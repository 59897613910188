@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: Circular;
  }
}

html,
body,
main {
  min-height: 100vh;
}

.ellipse-box-1 {
  display: -webkit-box;
  /*-webkit-line-clamp: 1;*/
  /*-webkit-box-orient: vertical;*/
}

.ellipse-box-2 {
  display: -webkit-box;
  /*-webkit-line-clamp: 2;*/
  /*-webkit-box-orient: vertical;*/
}

.ellipse-box-3 {
  display: -webkit-box;
  /*-webkit-line-clamp: 3;*/
  /*-webkit-box-orient: vertical;*/
}

.ellipse-box-4 {
  display: -webkit-box;
  /*-webkit-line-clamp: 4;*/
  /*-webkit-box-orient: vertical;*/
}

.ellipse-box-6 {
  display: -webkit-box;
  /*-webkit-line-clamp: 6;*/
  /*-webkit-box-orient: vertical;*/
}

.fadeImage {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 50%,
    transparent 100%
  );
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0px solid !important;
}

.carousel .thumb.selected > div {
  border: 2px solid !important;
  border-radius: 0.25rem;
  --tw-border-opacity: 1;
  border-color: rgb(234 233 231 / var(--tw-border-opacity));
}

.carousel .thumbs-wrapper {
  overflow: scroll !important;
}

.carousel .control-prev.control-arrow:before {
  border: solid black !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(135deg) !important;
}

.carousel .control-next.control-arrow:before {
  border: solid black !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(-45deg) !important;
}

.carousel .thumb {
  transition: none !important;
  border: none !important;
}

.react-multi-carousel-dot button {
  border-width: 1px !important;
  border-color: #9a9a9a !important; /*Neuttral 4*/
}
.react-multi-carousel-dot--active button {
  /* Nuetral-5 */
  background: #5e5e5e !important;
  border-color: #5e5e5e !important; /*Neuttral 4*/
}

/*Overwrite for PDP description container*/
.description-container strong,
.description-container b {
  font-family: var(--font-circular-medium);
}
.description-container ul,
.description-container ol {
  list-style: unset;
  padding: 10px 40px;
  margin: unset;
}

.description-container p {
  margin: 15px 0px;
}

/* Need it to overwrite Hubspot Chat positioning */
.hs-messages-mobile .pdp #hubspot-messages-iframe-container {
  bottom: 120px !important;
}

.hs-messages-mobile.hs-messages-widget-open
  .pdp
  #hubspot-messages-iframe-container {
  bottom: 0 !important;
}

.checkout-page #hubspot-messages-iframe-container {
  display: none !important;
}

/* .pillbar for Category Pill component in catalog page */
/* Firefox */
.pillbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Chrome, Edge, and Safari */
.pillbar::-webkit-scrollbar {
  display: none;
}

.carousel .slide img {
  width: auto;
}

.w-75-perc {
  width: 75% !important;
}

.z-index-100 {
  z-index: 100;
}

.loader-parent .flex-row {
  background: white;
  padding: 30px;
}

.loop-tooltip {
  background: #fdfdfd;
  box-shadow: 1px 1px 8px #bcbcbc;
  max-width: 290px;
  opacity: 1 !important;
}

.loop-tooltip-v2 {
  background: #fbfaf5 !important;
  border-radius: 17px !important;
  box-shadow: 1px 1px 8px #bcbcbc !important;
  max-width: 290px !important;
  opacity: 1 !important;
  padding: 25px 30px !important;
  z-index: 100 !important;
}
.loop-tooltip-v2::after {
  background-color: #fbfaf5 !important;
}

.videoContainer {
  height: 390px;
}

.videoContainerMobile {
  height: 100%;
}

.mobile .react-multi-carousel-dot-list {
  margin-bottom: 25px;
}

.tablet .react-multi-carousel-dot-list {
  margin-bottom: 80px;
  left: 50%;
}

.desktop .react-multi-carousel-dot-list {
  margin-bottom: 130px;
  left: 33%;
}

.react-multi-carousel-dot button {
  width: 8px !important;
  height: 8px !important;
}

.serviceFee .place-bottom::after {
  left: 15% !important;
}

.serviceFee .place-top::after {
  left: 29% !important;
}

.serviceFee .mobile .__react_component_tooltip {
  position: absolute !important;
  top: -140px !important;
  left: -54px !important;
}

.desktop-menu-item.active > div {
  font-weight: 600;
}
.desktop-menu-item > div::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e01605; /*Queen 1*/
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

.desktop-menu-item.active > div::after {
  opacity: 1;
}

.desktop-menu-item:first-child:hover > div::after,
.desktop-menu-item:last-child:hover > div::after,
.desktop-menu-item.active:first-child > div::after,
.desktop-menu-item.active:last-child > div::after {
  background: transparent !important;
  opacity: 0 !important;
}

.desktop-menu-item > section {
  opacity: 0;
  height: 0px;
  -webkit-transition: all 0.5s, -webkit-transform 0.5s;
  -moz-transition: all 0.5s, -moz-transform 0.5s;
  transition: all 0.5s, transform 0.5s;
}
.desktop-menu-item > section > div {
  display: none;
}
.desktop-menu-item.active > section {
  opacity: 1;
  height: auto;
}

.desktop-menu-item.active > section > div {
  display: grid;
}
