@font-face {
  font-family: "loop";
  src: url("../public/fonts/loop-icons.ttf?ky11rl") format("truetype"),
    url("../public/fonts/loop-icons.woff?ky11rl") format("woff"),
    url("../public/fonts/loop-icons.svg?ky11rl#loop") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "loop" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loop-icon-location:before {
  content: "\e90d";
}
.loop-icon-google:before {
  content: "\e90c";
}
.loop-icon-fb:before {
  content: "\e912";
}
.loop-icon-apple:before {
  content: "\e91f";
}
.loop-icon-account-bold:before {
  content: "\e900";
}
.loop-icon-account:before {
  content: "\e901";
}
.loop-icon-arrow-long-bold:before {
  content: "\e902";
}
.loop-icon-arrow-long:before {
  content: "\e903";
}
.loop-icon-arrow-short-bold:before {
  content: "\e904";
}
.loop-icon-arrow-short:before {
  content: "\e905";
}
.loop-icon-caret-down-bold:before {
  content: "\e906";
}
.loop-icon-caret-down:before {
  content: "\e907";
}
.loop-icon-caret-right-bold:before {
  content: "\e908";
}
.loop-icon-caret-right:before {
  content: "\e909";
}
.loop-icon-cart-bold:before {
  content: "\e90a";
}
.loop-icon-cart:before {
  content: "\e90b";
}
.loop-icon-checkmark-bold:before {
  content: "\e914";
}
.loop-icon-checkmark:before {
  content: "\e915";
}
.loop-icon-times-bold .path1:before {
  content: "\e916";
  color: rgb(20, 33, 56);
}
.loop-icon-times-bold .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(18, 18, 18);
}
.loop-icon-times-bold .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(20, 33, 56);
}
.loop-icon-times-bold .path4:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(18, 18, 18);
}
.loop-icon-times:before {
  content: "\e91a";
}
.loop-icon-filter-bold:before {
  content: "\e91b";
}
.loop-icon-filter:before {
  content: "\e91c";
}
.loop-icon-heart-bold:before {
  content: "\e91d";
}
.loop-icon-heart:before {
  content: "\e91e";
}
.loop-icon-link-bold .path1:before {
  content: "\e921";
  color: rgb(18, 18, 18);
}
.loop-icon-link-bold .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.loop-icon-link-bold .path3:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(18, 18, 18);
}
.loop-icon-link-bold .path4:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.loop-icon-link:before {
  content: "\e925";
}
.loop-icon-lock-bold:before {
  content: "\e926";
}
.loop-icon-lock:before {
  content: "\e927";
}
.loop-icon-magnify-bold:before {
  content: "\e928";
}
.loop-icon-magnify:before {
  content: "\e929";
}
.loop-icon-nav-bold:before {
  content: "\e92a";
}
.loop-icon-nav:before {
  content: "\e92b";
}
.loop-icon-plus-bold .path1:before {
  content: "\e92c";
  color: rgb(20, 33, 56);
}
.loop-icon-plus-bold .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(18, 18, 18);
}
.loop-icon-plus-bold .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(20, 33, 56);
}
.loop-icon-plus-bold .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(18, 18, 18);
}
.loop-icon-plus:before {
  content: "\e930";
}
.loop-icon-minus:before {
  content: "\2212";
}
.loop-icon-search-bold:before {
  content: "\e931";
}
.loop-icon-search:before {
  content: "\e932";
}
